.TextInputContainer {
	position: relative;
	margin-bottom: 6px;
}

.TextInput {
	border: none;
	border-bottom: 1px solid #b6b6b6;
	outline: none;
	width: 300px;
	height: 40px;
	text-align: buttom;
	font-weight: 700;
	background-color: white;
	font-size: 16px;
}

.TextInputLabel {
	position: absolute;
	font-weight: 700;
	left: 2px;
	top: 50%;
	transform: translateY(-50%);
	transition: all 130ms linear;
	transform-origin: top left;

	&:focus {
		top: -25%;
		transform: translateY(0%) scale(0.8);
	}

	&--active {
		top: -25%;
		transform: translateY(0%) scale(0.8);
	}

	&--dirty {
		top: -25%;
		transform: translateY(0%) scale(0.8);
	}

	&--error {
		color: red;
	}
}

.TextInputError {
	color: red;
	padding-top: 4px;
	text-align: 'right';
	font-size: 13px;
}
