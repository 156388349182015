.Printable {
	&__Content {
		position: absolute;
		transform: translateX(6000px);
	}

	&__PrintIcon--loading {
		cursor: progress;
		& path {
			fill: rgb(227, 224, 212);
		}
	}
}

@media print {
	* {
		box-sizing: border-box !important;
		font-size: 10px;
	}

	.Printable {
		&__Header {
			width: 100%;
			display: flex;
			justify-content: space-between;
			padding-bottom: 1.5rem;
			padding-top: 1rem;
			padding-left: 15px;
			padding-right: 30px;

			& > h1,
			h2 {
				width: fit-content;
				font-weight: 700;
			}
		}

		&__Content {
			position: relative;
			transform: translateX(0px);
			display: block;
			width: 100%;
			overflow: hidden !important;
			color: #000;

			& * {
				// margin-inline: 0;
				width: 100%;
			}

			& > h1 {
				font-size: 1rem;
				font-weight: 700;
				padding: 5mm;

				&::first-letter {
					text-transform: capitalize;
				}
			}
		}
	}

	.table {
		padding-left: 1rem !important;
		padding-right: 1rem !important;
		background-color: transparent !important;

		&__scrollable {
			&::-webkit-scrollbar {
				background-color: transparent !important;
			}
		}

		& > div {
			width: 100vw;
		}

		& > div > div {
			width: 100%;
		}
	}

	.table__header__cell {
		position: relative;
		background-color: transparent;
		color: #000;
		height: fit-content;
		min-width: 30px !important;

		&__data {
			display: block;
			position: relative;
			opacity: 1;
			justify-content: flex-start;
			text-align: center;
			font-size: 10px;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			padding-right: 1px;
			padding-left: 1px;

			&__date-string {
				font-size: 9px;
			}
			&--is-past {
				color: #000;
				opacity: 1;
			}
		}

		&:first-child {
			min-width: 100px !important;

			& > .table__header__cell__data {
				text-align: start;
			}
		}

		& > div {
			display: none;
		}
	}

	.table__row {
		height: fit-content;
		width: 100%;

		&:nth-child(odd) {
			background-color: #d4d4d4;
		}
	}

	.waste-table-row {
		height: fit-content;
		width: 100%;
		height: 16px;
		width: 100vw;

		&__data {
			max-height: 16px;
			font-size: 10px;
			justify-content: center;
		}

		&__header-row {
			// justify-content: flex-start;
			&--last {
				margin-left: 0px;
				justify-content: center;
			}
		}
	}

	.table__cell {
		color: #000;
		font-size: 10px;
		min-width: 30px !important;
		height: 100%;
		width: 100%;
		background-color: transparent;
		padding-top: 2px;
		padding-bottom: 2px;

		&__wrapper {
			width: 100%;
		}

		&--greyed {
			background-color: transparent;
			border: none;
		}

		&:first-child {
			background-color: transparent;
			color: #000;
			min-width: 100px !important;
		}
	}

	.table-cell__input {
		font-size: 10px;
		height: 100%;
		width: 100%;
		background-color: transparent;
		font-family: 'ITC Stone Sans', Arial, sans-serif;
	}

	.table-cell__lock {
		& > .no-print {
			display: none;
		}

		width: auto;
		top: -2px;
	}

	.amount-input {
		height: fit-content;
		& button {
			display: none;
		}

		& > .amount-input__input {
			border: none;
			font-size: 10px;
			max-height: 16px;
			background-color: transparent;
			text-align: left;
			max-width: fit-content;
		}
	}
	.header-container__title::first-letter {
		text-transform: capitalize;
	}
	.table-cell--total {
		background-color: transparent !important;
	}
}
