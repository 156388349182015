.add-reduce-button {
	width: 100%;
	height: 100%;
	background-color: #ffffff;
	border-radius: 4px;
	border: 1px solid #cfb170;
	color: #cfb170;
	font-size: 20px;
	cursor: pointer;
	transition: background-color ease-out 0.2s;
	display: flex;
	align-items: center;
	justify-content: center;

	&:hover {
		background-color: rgba(0, 0, 0, 0.005);
	}

	&:focus {
		outline-color: #cfb170;
	}
}
