.login-container {
  display: flex;
  flex-direction: column;
  background: white;
  width: 490px;
  border-radius: 10px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.5);
  align-items: center;
  height: 100%;
  max-height: 771px;
  display: flex;
  align-items: center;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    padding: 47px 0;

    &__header {
      margin-top: auto;
      margin-bottom: 44px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-self: flex-end;
    }

    &__copyright-notice {
      font-size: 12px;
      color: #868686;
      font-weight: 400;
      margin-top: auto;
      justify-self: flex-end;
    }

    &__login-icon {
      width: 71px;
      height: 68.21px;
      border-radius: 50%;
      background-color: #cfb170;
    }

    &__title {
      font-size: 40px;
      font-family: ITC Stone Serif;
      color: black;
      font-weight: bold;
    }
  }
}
