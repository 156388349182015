.Checkbox {
	width: 18px;
	height: 18px;
	border: 1px solid rgba(0, 0, 0, 0.4);
	border-radius: 4px;
	color: grey;
	transition: all 0.2s ease-in-out;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;

	&:hover {
		cursor: pointer;
		// scale: 1.08;
	}

	&--Checked {
		background-color: #63c19d;
		color: #fff;
		border-color: #63c19d;

		&:hover {
			border-color: #63c19d;
		}
	}

	& > .Icon {
		position: absolute;
		inset: 0;
		width: 100%;
		height: 100%;
		margin: auto;
		transform: translateY(3px);
	}
}
