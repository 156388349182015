.table {
	position: relative;

	&__row {
		height: 54px;
		width: fit-content;

		&:hover {
			background-color: rgb(255, 255, 255, 0.4);
		}
	}

	&__cell {
		display: flex;
		align-items: center;
		height: 100%;

		&--shadowed {
			// box-shadow: 5px 0 5px -2px rgb(0 0 0 / 10%);
			border-right: 1px solid rgb(227, 224, 212);
		}

		&:first-child {
			background-color: #f7f4e9;
		}

		&:hover {
			background-color: rgb(255, 255, 255, 0.9);
		}

		&--title {
			&:hover {
				background-color: inherit;
			}
		}
	}

	&__header {
		z-index: 1;
		width: 100%;
		position: sticky;
		top: 0;
		background-color: #f7f4e9;
		&__cell {
			height: 54px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			text-align: center;
			background-color: #f7f4e9;

			&--shadowed {
				// box-shadow: 5px 0 5px -2px rgb(0 0 0 / 20%);
				border-right: 1px solid rgb(227, 224, 212);
			}

			&__data {
				width: 100%;
				display: flex;
				justify-content: center;
				font-weight: 700;
				font-family: 'ITC Stone Sans';
				font-size: 16px;
				&__date-string {
					display: flex;
					justify-content: center;
					font-weight: 400;
					color: #797979;
					font-size: 12px;
				}
				&--title {
					justify-content: flex-start;
				}
				&--is-past {
					opacity: 0.3;
				}
			}
		}
	}

	&__table-header {
		width: 122px;
		height: 56px;
		font-size: 16px;
		font-weight: 700;
		align-content: flex-start;
	}

	&__scrollable {
		height: 100%;

		&::-webkit-scrollbar {
			background-color: #f7f4e9;
			// width: 12px;
			height: 12px;
		}

		&::-webkit-scrollbar-thumb {
			// background-color: darken(#f7f4e9, 20%);
			background-color: #b2afa4;
			// width: 10px;;
			height: 10px;
		}

		&::-webkit-scrollbar-corner {
			background-color: #f7f4e9;
		}
	}
}

.waste-table-row {
	align-items: center;
	height: 56px;
	border-bottom: solid 1px #e3e0d4;
	&__data {
		display: flex;
		font-size: 16px;
		font-weight: 400;
		justify-content: flex-end;
		box-sizing: border-box;
		&:first-child {
			justify-content: start;
		}
		&:last-child {
			justify-content: center;
			margin-left: 50px;
		}
		&:hover {
			background-color: transparent;
		}
	}

	&__header-row {
		justify-content: flex-end;

		&--last {
			justify-content: center;
			margin-left: 5px;
		}
	}
}

@media (pointer: coarse) {
	.table {
		&__scrollable {
			// &::after {
			// 	border-right-color: transparent;
			// }
		}
	}
}

@media (max-width: 820px) {
	.table {
		&__scrollable {
			&::after {
				width: 60px;
			}
		}
	}
}
