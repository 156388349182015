.header-container {
	margin-bottom: 20px;
	display: flex;
	align-items: center;

	&__title {
		display: block;
		font-family: ITC Stone Serif;
		font-weight: 900;
		font-size: 3vmin;
		align-items: center;
		align-self: center;
		margin-right: auto;

		&::first-letter {
			text-transform: capitalize;
		}
	}

	&__actions {
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}

	&__divider {
		width: 1px;
		background-color: #e3e0d4;
		height: 40px;
		margin-left: 15px;
		margin-right: 15px;
	}

	&__button {
		display: flex;
		align-items: center;
	}
}

@media (max-width: 1200px) {
	.header-container {
		&__title {
			font-size: 25px;
		}
	}
}
