$border-gray: #e3e0d4;
$border-gold: #cfb170;
$total: #faf8f1;
$grayed-out: rgb(0, 0, 0, 0.03);

.table-cell {
	width: 100%;
	height: 100%;
	min-width: 120px;
	display: flex;
	justify-content: center;
	align-items: center;

	font-size: 16px;
	font-weight: 400;
	text-align: center;

	border-right: $border-gray solid 1px;

	transition: all 0.2s;

	outline: 0;

	&:first-child {
		border-left: $border-gray solid 1px;
	}

	&__wrapper {
		position: relative;
		height: 100%;
	}

	&__input {
		width: 100%;
		height: 100%;
		background: 0;
		border: 0;
		text-align: center;
		outline: 0;
		font-weight: 400;
		font-size: 16px;
		cursor: auto;
		box-sizing: border-box;
		border: 1px solid transparent;
		font-weight: inherit;
		font-family: 'ITC Stone Sans', Arial, sans-serif;
		&--editable {
			&:focus {
				background: #fff;
				border: 1px solid $border-gold;
				outline: 0;
			}
		}
	}
	&--greyed {
		background-color: $grayed-out;
		&:hover {
			background-color: $grayed-out;
		}
	}

	&__lock {
		position: absolute;
		top: 3px;
		right: 0;
	}

	&--total {
		font-weight: 700;
		background-color: $total;
		&__text {
			height: fit-content;
			cursor: default;
		}
	}
}
