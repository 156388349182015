.auxiliary-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 22px;
  width: min-content;
  padding-right: 32px;

  > * + * {
    margin-left: 18px;
  }
}

@media (max-width: 820px) {
  .auxiliary-actions {
    &:last-child {
      padding-right: 25px;
    }
  }
}
