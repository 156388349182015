.template-page {
	display: grid;
	grid-template-columns: 50% 50%;
	grid-template-rows: 90px 90px auto 70px;
	height: 100vh;
	width: 100%;
	justify-content: center;
	background-color: #f7f4e9;

	&__content {
		grid-column-start: 1;
		grid-column-end: 3;
		grid-row-start: 3;
		grid-row-end: 4;
		justify-self: center;
		margin-inline: 120px;
		overflow-y: scroll;
		width: fit-content;
		&::-webkit-scrollbar {
			display: none;
		}
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
	}
	&__footer {
		grid-column-start: 1;
		grid-column-end: 3;
		grid-row-start: 4;
		grid-row-end: 4;
		box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%);
		width: 100%;
		z-index: 2; // To get the box-shadow to show up
	}
	&__header {
		grid-column-start: 1;
		grid-column-end: 3;
		grid-row-start: 2;
		grid-row-end: 3;
		margin-inline: 120px;
	}
}
