.order-page {
	display: grid;
	grid-template-columns: 50% 50%;
	grid-template-rows: 90px 90px auto 70px;
	height: 100vh;
	width: 100%;
	justify-content: center;
	background-color: #f7f4e9;
	margin: auto;

	&__content {
		grid-column-start: 1;
		grid-column-end: 3;
		grid-row-start: 3;
		grid-row-end: 4;
		overflow: hidden;

		& > div {
			margin-left: 120px;
			padding-right: 120px - 12px; // Compensate for scrollbar
		}
	}
	&__footer {
		grid-row-start: 4;
		grid-row-end: 4;
		box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%);
		position: fixed;
		bottom: 0;
		right: 0;
		left: 0;
		z-index: 2; // To get the box-shadow to show up
	}
	&__header {
		grid-column-start: 1;
		grid-column-end: 3;
		grid-row-start: 2;
		grid-row-end: 3;
		margin-inline: 120px;
	}
}

@media (max-width: 820px) {
	.order-page {
		&__content > div {
			margin-left: 60px;
			padding-right: 60px;
		}
		&__header {
			margin-inline: 60px;
		}
	}
}
