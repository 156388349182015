.LoaderButton {
  border: solid 1px #63c19d;
  background-color: #63c19d;
  width: 54px;
  height: 54px;
  border-radius: 50%;
  transition: all 250ms ease-out;
  justify-content: center;
  align-items: center;
  position: relative;
  outline: none;

  &:not(.isLoading) {
    cursor: pointer;
  }
  
  &.isLoading, 
  &:hover {
    .LoaderButton__Label {
      opacity: 1;
    }
  }

  &:hover:not(.isLoading) {
    background-color: darken(#63c19d, 10%);
  }

  &__Icon {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;

    .Icon {
      cursor: inherit;
    }
  }

  &__Icon--Loader{
    .Icon__element {
      animation: Spin 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    }
  }

  &__Label {
    opacity: 0.6;
    font-size: 12px;
    color: #797979;
    position: absolute;
    top: 100%;
    padding-top: 5px;
    left: 0;
    right: 0;
    text-align: center;
    letter-spacing: -0.09px;
    transition: all 250ms ease-out;
  }
}

@keyframes Spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}