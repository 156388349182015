@import url(react-datepicker/dist/react-datepicker.css);

$selected-color: #63c19d;
$hover-color: rgb(99, 193, 157);
$shadow: rgba(0, 0, 0, 0.07) 0 0 10px 0;

.react-datepicker {
	// position: absolute;
	// inset: 0px auto auto 0px;
	// transform: translate3d(0px, -2px, 0px);
	z-index: 2;
	border: 1px solid transparent;
	box-shadow: $shadow;
	border-radius: 10px;

	&__button {
		background-color: #f7f4e9;
		outline: 0;
		border: 0;
		width: 22px;
		padding: 0;
	}
	&__triangle {
		display: none;
		background-color: transparent;
		border-color: transparent;

		&::before {
			border-bottom-color: transparent;
		}

		&::after {
			border-bottom-color: transparent;
		}
	}

	&__range {
		height: 54px;
		display: flex;
		align-items: center;
		background-color: rgba(255, 255, 255, 0.4);
		outline: 0;
		border: 0;
		padding-block: 0.5rem;
		padding-inline: 1.35rem;
		cursor: pointer;
		border: 1px solid #e3e0d4;
		border-radius: 27px;
		transition: background-color 200ms ease-in-out;

		&:focus,
		&:hover {
			border: 1px solid rgb(207, 177, 112);
			background-color: #fff;
			// box-shadow: $shadow;
		}

		&__input {
			outline: 0;
			border: 0;
			pointer-events: none;
			background-color: transparent;
			max-width: 25ch;
			font-family: 'ITC Stone Sans', Arial, sans-serif;
			font-size: 16px;
			transform: translateY(-1px);
		}

		& > .Icon {
			transform: translateY(-2px);
		}
	}
}

.react-datepicker__triangle::after,
.react-datepicker__triangle::before {
	border-bottom-color: #fff;
}

.react-datepicker-popper {
	// Overrides the default z-index
	z-index: 800 !important;
}

.react-datepicker__header {
	background-color: #fff;
	border-bottom: none;
	padding: 0;
}

.react-datepicker__month-container {
	padding: 28px;
}

.react-datepicker__current-month {
	padding-bottom: 28px;
	text-transform: capitalize;
	font-size: 16px;
	font-weight: 900;
	letter-spacing: -0.12px;
}

.react-datepicker__day-name {
	color: rgba(0, 0, 0, 0.4);
	width: 32px;
}

.react-datepicker__day {
	width: 32px;
	height: 32px;
	line-height: 34px;
	border-radius: 50%;

	&:hover {
		background-color: $hover-color;
		border-radius: 50%;
		color: #fff;
	}
}

.react-datepicker__day--selected {
	background-color: $selected-color;
}

.react-datepicker__day--keyboard-selected {
	background-color: #fff;
	color: #000;
}

.react-datepicker__week-number {
	color: rgba(0, 0, 0, 0.4);
}

.DatePickerHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 6px;
	padding-bottom: 16px;
	&__button {
		outline: none;
		border: none;
		background-color: transparent;
		border-radius: 50%;
		height: 32px;
		aspect-ratio: 1/1;
		display: flex;
		justify-content: center;
		align-items: center;
		border: 1px solid transparent;
		cursor: pointer;

		&:hover {
			border-color: #cfb170;
		}

		& > .Icon {
			transform: translate(-1px, 1px);
		}

		&--next {
			transform: rotate(180deg);
		}
	}

	&__title {
		font-size: 16px;
		font-weight: bold;
	}
}

.react-datepicker__day {
	margin-inline: 0;
	position: relative;
}

.react-datepicker__week {
	display: flex;
	align-items: center;
	gap: calc(0.166rem * 2);
}

.react-datepicker__day--in-range {
	border-radius: 0;

	&::after {
		position: absolute;
		right: -0.332rem;
		content: '';
		background-color: $selected-color;
		height: 100%;
		width: calc(0.166rem * 2);
	}

	&:hover {
		border-radius: 0;
	}
}

.react-datepicker__day--range-start {
	border-radius: 50% 0 0 50%;

	&:hover {
		border-radius: 50% 0 0 50%;
	}
}

.react-datepicker__day--range-end {
	border-radius: 0 50% 50% 0;

	&::after {
		background-color: transparent;
	}

	&:hover {
		border-radius: 0 50% 50% 0;
	}
}

.react-datepicker__day--range-end.react-datepicker__day--range-start {
	border-radius: 50%;

	&::after {
		background-color: transparent;
	}

	&:hover {
		border-radius: 50%;
	}
}

.react-datepicker__day--in-range {
	background-color: $selected-color;
}

.react-datepicker__day--in-selecting-range:not(
		.react-datepicker__day--in-range,
		.react-datepicker__month-text--in-range,
		.react-datepicker__quarter-text--in-range,
		.react-datepicker__year-text--in-range
	) {
	background-color: $hover-color;
}
