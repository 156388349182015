.nav-button {
	width: 132px;
	padding-top: 16px;
	border-bottom: solid 4px white;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	opacity: 40%;
	transition: all 0.3s ease;
	height: 100%;

	&:hover {
		border-bottom: solid #63c19d5d 4px;
		cursor: pointer;
		opacity: 100%;
	}

	&__title {
		font-weight: 700;
		font-size: 11px;
		margin-bottom: 16px;
		margin-top: 5px;
		cursor: pointer;
	}
	&--active {
		border-bottom: solid #63c19d 4px;
		opacity: 100%;

		&:hover {
			border-bottom: solid #63c19d 4px;
			opacity: 100%;
		}
	}

	&--logout {
		border-bottom: none;

		&:hover {
			border-bottom: none;
		}
	}
}
