@import url('https://fast.fonts.net/t/1.css?apiType=css&projectid=00e35196-3793-44e1-b61d-1cd758fc3cb3');

@font-face {
	font-family: 'ITC Stone Serif';
	src: url('./../public/Assets/Fonts/bd5074ee-43ae-4be0-94ac-cb71b8909840.woff')
			format('woff2'),
		url('./../public/Assets/Fonts/bd5074ee-43ae-4be0-94ac-cb71b8909840.woff')
			format('woff');
	font-weight: 700;
	font-style: normal;
}
@font-face {
	font-family: 'ITC Stone Serif';
	src: url('./../public/Assets/Fonts/3468c8e2-0ea0-43fc-9027-60f018832208.woff2')
			format('woff2'),
		url('./../public/Assets/Fonts/ced1e80b-c390-4322-8b8c-0dcccafc09b4.woff')
			format('woff');
	font-weight: 700;
	font-style: italic;
}
@font-face {
	font-family: 'ITC Stone Sans';
	src: url('./../public/Assets/Fonts/3f6c1f98-7039-4a0d-98da-5475646b2da6.woff2')
			format('woff2'),
		url('./../public/Assets/Fonts/0e3c43a9-17e4-4bdc-9ef9-67238c840243.woff')
			format('woff');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'ITC Stone Sans';
	src: url('./../public/Assets/Fonts/449b301a-c60b-475b-9103-d89231d87cf6.woff2')
			format('woff2'),
		url('./../public/Assets/Fonts/e15ece9f-8036-48da-a73b-fe37dbe4ddde.woff')
			format('woff');
	font-weight: 400;
	font-style: italic;
}
@font-face {
	font-family: 'ITC Stone Sans';
	src: url('./../public/Assets/Fonts/274b13f1-3fab-48d9-b700-3d6b660fc714.woff2')
			format('woff2'),
		url('./../public/Assets/Fonts/669fbbd6-f72e-4386-91ed-2fda8279a318.woff')
			format('woff');
	font-weight: 700;
	font-style: normal;
}
react-datepicker-popper,
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	font-family: 'ITC Stone Sans', 'ITC Stone Serif';
	vertical-align: baseline;
	box-sizing: border-box;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}
html {
	// Needed to place the printable version of whatever outside the viewport (without it trying to scroll)
	overflow: hidden;
}
button {
	padding: 0;
	margin: 0;
}
body {
	line-height: 1;
}
ol,
ul {
	list-style: none;
}
blockquote,
q {
	quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
	content: '';
	content: none;
}
body {
	margin: 0;
}

#root {
	height: 100vh;
	background-color: #f7f4e9;
}
