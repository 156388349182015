.options-wrapper {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
}

.options {
	width: 100%;
	height: 100%;
	display: flex;
	border: 0;
	justify-content: space-evenly;
	font-weight: 400;
	align-items: center;
	transition: background-color ease-in-out 0.2s;
	cursor: pointer;
	background-color: #fff; // Choke the shadow on the options-list
	z-index: 2; // Choke the shadow on the options-list
	padding-inline: 20px;
	gap: 10px;

	&:hover {
		background-color: #ededed;
	}

	&__icon {
		height: fit-content;
		display: flex;
		align-items: center;
		transition: transform ease-in-out 0.4s;
		&--active {
			transform: rotateX(180deg);
		}
	}
	&__header {
		height: fit-content;
		align-items: center;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		width: 100%;
	}
}
.options-list {
	position: absolute;
	bottom: 70px;
	height: fit-content;
	right: 1px;
	z-index: 1;
	box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%);

	&__item {
		width: 100%;
		max-width: 250px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		text-align: center;
		line-height: 40px;
		padding-inline: 20px;
		height: 40px;
		background-color: white;
		align-items: center;
		justify-content: center;
		font-weight: 400;
		border-bottom: solid 1px #e3e0d4;
		border-left: solid 1px #e3e0d4;
		transition: background-color ease-in-out 0.2s;
		cursor: pointer;

		&.is-active {
			background-color: #63c19d;
			color: #fff;
		}

		&:hover:not(.is-active) {
			background-color: #cfb170;
			color: #fff;
		}

		&:first-child {
			border-top: solid 1px #e3e0d4;
		}
	}
}
