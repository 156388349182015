$green: #63c19d;
$gold: #cfb170;
$orange: #e6a210;

.date-picker {
  flex-direction: row;
  align-items: center;
  height: 54px;
  display: flex;

  label {
    cursor: pointer;
  }

  &__action-container {
    display: flex;
    position: relative;
  }

  &__date-status {
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    top: 2px;
    right: 2px;

    &--archived {
      background-color: $green;
      border: solid 1px #fff;
    }

    &--partial {
      border: solid 1px #fff;
      background-color: $orange;
    }
  }

  &__arrow-left {
    display: flex;
    height: 16px;
    transform: rotate(180deg);
    margin-right: 10px;
    cursor: pointer;
  }
  &__arrow-right {
    display: flex;
    height: 16px;
    cursor: pointer;
    margin-left: 15px;
  }

  &__dates {
    display: flex;
    flex-direction: row;

    &__date {
      display: flex;
      width: 54px;
      height: 54px;
      border-radius: 50%;
      border: solid 1px #cfb170;
      background-color: #fff;
      justify-content: center;
      align-items: center;
      font-weight: 700;
      transition: background-color 0.3s ease;
      margin-left: 5px;

      &--active {
        border: solid 1px $green;
        background-color: $green;

        &:hover {
          background-color: $green !important;
        }
      }

      &--passed {
        border: solid 1px $gold;
        filter: grayscale(80%);
      }

      &:hover {
        cursor: pointer;
        background-color: $gold;
        color: #fff;
      }

      &__label {
        padding-top: 3px;
        &--passed {
          filter: opacity(30%);
        }
        &--active {
          color: #fff;
        }
        &--default {
          color: #000;
        }
      }
    }
  }
}
