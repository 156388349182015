.amount-input {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 100%;
	justify-self: flex-end;
	height: 44px;

	& > .add-reduce-button {
		max-width: 44px;
	}

	&__input {
		width: 100%;
		height: 100%;
		padding: 0;
		margin: 5px;
		border-radius: 4px;
		border: 1px solid #cfb170;
		font-size: 16px;
		font-weight: 400;
		text-align: center;
		box-sizing: border-box;
		font-family: 'ITC Stone Sans', Arial, sans-serif;

		&:focus {
			outline-color: #cfb170;
		}
	}
}
