.button {
  width: 100%;
  height: 100%;
  border-radius: 27px;
  background-color: #cfb170;
  color: white;
  border: 0;
  transition: all 250ms ease-out;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  outline: none;

  &:hover {
    background-color: darken(#cfb170, 10%);
  }
}
