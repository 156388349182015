.waste-table {
  &__table {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  &__header {
    width: 100%;
    &__row {
      display: grid;
      grid-template-columns: 30% 15% 15% 15% 25%;
      align-items: center;
      height: 54px;
      &__data {
        display: flex;
        font-weight: 700;
        font-size: 16px;
        justify-content: flex-end;
        &:first-child {
          justify-content: start;
        }
        &:last-child {
          justify-content: flex-end;
          padding-right: 91px;
        }
      }
    }
  }
  &__body {
    width: 100%;
    &__row {
      display: grid;
      grid-template-columns: 30% 15% 15% 15% 25%;
      border-top: solid 1px #e3e0d4;
      align-items: center;
      height: 54px;
      &__data {
        display: flex;
        font-size: 16px;
        font-weight: 400;
        justify-content: flex-end;
        &:first-child {
          justify-content: start;
        }
        &:last-child {
          justify-content: center;
          margin-left: 50px;
        }
      }
      &:last-child {
        border-bottom: solid 1px #e3e0d4;
      }
    }
  }
}
