.actions-container {
  display: flex;
  align-items: center;
  height: 54px;

  &__auxiliary-actions {
    display: flex;
    align-items: center;
    height: 54px;
  }
}
