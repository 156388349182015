.LoginForm {
  display: flex;
  flex-direction: column;

  &__text-input {
    padding-top: 17px;
  }

  &__button-input {
    margin-top: 35px;
    height: 54px;
  }
}
