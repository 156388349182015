.login-page {
  display: flex;
  justify-content: flex-end;
  &__login-container {
    margin: 25px 25px 25px 0;
    display: flex;
    align-items: center;
  }
  background-image: url("../../public/Assets/Images/2600_Broed_Guldaegget_Quantity-Digital_Esb_feb21_002.jpg");
  background-size: cover;
  height: 100%;
}
