.footer-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	background-color: #ffffff;
	width: inherit;
	height: 70px;

	&__locations {
		display: flex;
		// width: 180px;
		min-width: 180px;
		max-width: 250px;
		height: 70px;
		border-left: solid 1px #e3e0d4;
	}
	&__button-group {
		display: flex;
		flex-direction: row;
	}
	&__log-out {
		display: flex;
		border-right: solid 1px #e3e0d4;
	}
}
