.Spinner {
	display: inline-block;
	position: relative;
	// width: 80px;
	// height: 80px;
}
.Spinner div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	// width: 64px;
	// height: 64px;
	// margin: 8px;
	border-style: solid;
	border-color: currentColor;
	// border: 8px solid currentColor;
	border-radius: 50%;
	animation: Spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: currentColor transparent transparent transparent;
}
.Spinner div:nth-child(1) {
	animation-delay: -0.45s;
}
.Spinner div:nth-child(2) {
	animation-delay: -0.3s;
}
.Spinner div:nth-child(3) {
	animation-delay: -0.15s;
}
@keyframes Spinner {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}