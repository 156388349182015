.PopoverContent {
	z-index: 200;
	border-radius: 1rem;
	// padding-block: 10px;
	width: 260px;
	background-color: white;
	box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
		hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
	animation-duration: 400ms;
	animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
	will-change: transform, opacity;
}
.PopoverContent[data-state='open'][data-side='top'] {
	animation-name: slideDownAndFade;
}
.PopoverContent[data-state='open'][data-side='right'] {
	animation-name: slideLeftAndFade;
}
.PopoverContent[data-state='open'][data-side='bottom'] {
	animation-name: slideUpAndFade;
}
.PopoverContent[data-state='open'][data-side='left'] {
	animation-name: slideRightAndFade;
}

.IconButton {
	all: unset;
	position: relative;
}

.PopoverClose {
	all: unset;
	font-family: inherit;
	height: 22px;
	width: 22px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 5px;
	right: 5px;
}

.IconButton {
	font-family: inherit;
	aspect-ratio: 1;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.Filter {
	display: flex;
	flex-direction: column;
	max-height: 400px;
	overflow: hidden;

	&__Header {
		padding-inline: 20px;
		padding-bottom: 0.8rem;
		padding-top: 0.8rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid #e3e0d4;

		&-Actions {
			display: flex;
			gap: 0.4rem;

			& > button {
				all: unset;
				font-family: inherit;
				font-size: 0.8rem;
				color: rgba(0, 0, 0, 0.5);
				cursor: pointer;

				&:hover {
					color: rgba(0, 0, 0, 0.7);
				}
			}
		}
	}

	&__Locations {
		height: 100%;
		overflow: auto;

		&::-webkit-scrollbar {
			background-color: #f7f4e9;
			width: 8px;
		}

		&::-webkit-scrollbar-thumb {
			// background-color: darken(#f7f4e9, 20%);
			background-color: #b2afa4;
		}

		&::-webkit-scrollbar-corner {
			background-color: #f7f4e9;
		}
	}

	&__Title {
		font-size: 0.8rem;
	}

	&__Form-Element {
		padding-block: 0.4rem;
		display: flex;
		align-items: center;
		gap: 0.4rem;
		padding-inline: 20px;
		cursor: pointer;
		user-select: none;
		color: rgba(0, 0, 0, 0.4);

		&:last-child {
			margin-bottom: 0.4rem;
		}

		&:first-child {
			margin-top: 0.4rem;
		}

		&:hover {
			background-color: rgba(0, 0, 0, 0.06);
		}

		&--Checked {
			color: #000;
		}
	}

	&__Active {
		position: absolute;
		top: -5px;
		right: -6px;
		width: 12px;
		height: 12px;
		background-color: #63c19d;
		border: 1px solid #ffffff;
		border-radius: 100%;
		pointer-events: none;
	}
}

@keyframes slideUpAndFade {
	from {
		opacity: 0;
		transform: translateY(2px);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes slideRightAndFade {
	from {
		opacity: 0;
		transform: translateX(-2px);
	}
	to {
		opacity: 1;
		transform: translateX(0);
	}
}

@keyframes slideDownAndFade {
	from {
		opacity: 0;
		transform: translateY(-2px);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes slideLeftAndFade {
	from {
		opacity: 0;
		transform: translateX(2px);
	}
	to {
		opacity: 1;
		transform: translateX(0);
	}
}
